<template>
  <div class="header">
  <!-- <div class="logo" >
      <img :src="logo_url" alt="">
      </div> -->
      <img :src="banner[0].url" alt="" v-if="(banner.length == 1)" />
    <div class="banner">
      <span @click="gohome">返回网站首页</span>
    </div>
    <div class="top" v-if="!show">
 
      <span v-if="$route.query">{{ $route.query.name }}</span>
      <span @click="isShow" style="float:right"
        ><van-icon name="arrow-down"
      /></span>
    </div>
    <div class="tab" v-else>
      <div class="title">
        <span v-if="$route.query">{{ $route.query.name }}</span>
        <span @click="isShow"><van-icon name="arrow-up"/></span>
      </div>
      <van-collapse class="dym_collapse" v-model="activeName" accordion  @change="mobOpen">
        <div v-if="dataList&&dataList.modular">
          <van-collapse-item
            class="collapse_item"
            :title="item.name"
            :name="index + 1"
            v-for="(item, index) in dataList.modular"
            :key="index"
            ></van-collapse-item
          >
        </div>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {},
    banner:{}
  },
  data() {
    return {
      logo_url:JSON.parse(localStorage.getItem("themeOther")).logo_url,
      activeName: [1, 2, 2],
      show: false,
      upd:0,
    };
  },
  mounted() {
      console.log(this.dataList);
    console.log(this.$route.query);
  },
  methods: {
    isShow() {
      this.show = !this.show;
      this.upd++
    },
    mobOpen(value){
      this.show=!this.show
      this.$emit('mobopen',value);
    },
    gohome() {
      console.log(this.$store.state.roleSchool);
      this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
    },
    tabs(){

    }
  },
  
};
</script>

<style lang="scss" scoped>
@import "../../../style/default/default.scss";
.header {
  color: $blue;
  box-sizing: border-box;
  width: 100%;
  height: 18.75rem;
  max-width: 780px;
  position: relative;
  background: url("../../../assets/列表banner@2x.png");
  background-size: 100% 100%;
  .top{width: 100%;
    :nth-child(1){
      display: inline-block;
      width: 80%;
      overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
    }
  }
  .logo{
    position: absolute;
    top: 6.375rem;
    width: 3.75rem;
    z-index: 999;
    left: 20px;
    height: 3.75rem;
    
    img{
      height: 100%;
      width: 100%;
    }}
  img{
      width: 100%;
      height: 100%;
    }
  .banner {
    font-size: 1.525rem;
    background: white;
    padding: 10px 15px;
    position: absolute;
    z-index: 0;
    border-radius: 9rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
  .top {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    z-index: 99;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 5.625rem;
    width: 100%;
    background: #ffffff;
    color: $blue;
    font-size: 1.875rem;
    line-height: 5.625rem;
  }
  .tab {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 99;
    background: white;
    opacity: 0.8;
    .dym_collapse {
      /deep/ .collapse_item {
        background: $blue;
        .van-collapse-item__content {
          color: white;
          padding-left: 5.125rem;
          background: $blue;
          font-size: 1.875rem;
          p {
            margin: 10px;
            &:nth-child(1) {
              margin-top: 20px;
            }
          }
        }
        .van-cell {
          color: white;
          background: $blue;
          .van-cell__title {
            color: white;
            background: $blue;
            height: 4rem;
            font-size: 1.875rem;
            line-height: 4rem;
            .van-icon {
              color: white;
              line-height: 4rem !important ;
            }
            .van-cell__right-icon,
            .van-icon {
              background: $blue;
              line-height: 5rem;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      height: 5.625rem;
      padding: 0 20px;
      font-size: 1.875rem;
      line-height: 5.625rem;
      border-bottom: 1px solid #cccccc;
    }
  }
}
</style>
