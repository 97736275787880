<template>
  <div class="copyright">
    {{ copyright ? copyright : "" }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      copyright: "",
    };
  },
  mounted() {
    console.log(this.$store.state.themeOther.copyright);
    if (this.$store.state.themeOther.copyright) {
      this.copyright = this.$store.state.themeOther.copyright;
    }
  },
  props: {
    copyRight: {
      default: "晨旭",
    },
  },
};
</script>

<style>
.copyright {
  margin-top: 2.5rem;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #cccccc;
}
</style>