<template>
  <div class="header">
    <!-- <div class="logo" style="height:60px;width:60px">
      <img :src="logo_url" alt="">
      
      
      </div> -->
    <img :src="banner[0].url" alt="" v-if="(banner.length == 1)" />
    <div class="block" v-else>
      <el-carousel height="300px">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="nav">
      <div class="nav_left">
        {{ title }}
      </div>
      <div class="nav_center">
        <div class="nav_title" @click="tabs(index, item)" :class="[currentIndex == index ? 'nav_title_current' : '']"
          v-for="(item, index) in dataList" :key="index">{{ item.name }}</div>
      </div>
      <div class="nav_right" @click="goHome">返回网站首页</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo_url: JSON.parse(localStorage.getItem("themeOther")).logo_url,
    };
  },
  props: {
    dataList: {},
    currentIndex: { default: 1 },
    banner: {},
    title: "",
  },
  created() {
    setTimeout(() => {
      console.log(this.dataList);
    }, 500)
  },
  methods: {
    tabs(value, item) {
      this.$emit('tabs', value, item);
    },
    goHome() {
      this.$router.push({ path: `/${localStorage.getItem('urlL')}` })
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../style/default/default.scss";

.header {
  position: relative;
  width: 100%;
  height: 370px;

  .logo {
    position: absolute;
    left: 60px;
    top: 85px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .nav {
    display: flex;
    z-index: 99;
    position: absolute;
    top: 0;
    line-height: 70px;
    text-align: center;
    width: 100%;
    height: 70px;
    background: white;

    .nav_left {
      color: #FFFFFF;
      font-weight: bold;
      background: $brown;
      flex: 2;
    }

    .nav_center {
      flex: 5;
      display: flex;

      .nav_title {
        cursor: pointer;
        flex: 2;
      }

      .nav_title_current {
        flex: 2;
        color: $blue;
        font-weight: bold;
      }
    }

    .nav_right {
      color: $blue;
      flex: 1;
    }
  }
}
</style>
